import React from 'react';
import { Link } from "react-router-dom"

const Blog1 = () => {
    return (
      <div id="top">
        <div id="fh5co-about" className="animate-box">
            <div className="container">
            <div className="row">
                <div className="col-md-10 col-md-offset-1 text-center fh5co-heading">
                <div className="row">
                    <div className="col-md-9 text-left">
                    <h2>Policies</h2>
                    </div>
                    <div className="col-md-3">
                    <Link to="/" className='btn btn-sm btn-dark text-right'>Go back</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-left">
                    <br /> <br />
                    {/* INIZIO INFORMATIVA PRIVACY */}
                    <p><i>This privacy statement was last updated on 24 January 2022 and applies to citizens and legal permanent residents of the European Economic Area.</i></p>
                    <p>In this privacy statement, we explain what we do with the data we obtain from you through <a href="https://www.stefanocoggiola.com">https://www.stefanocoggiola.com</a>. We recommend that you read the declaration carefully. When processing we comply with the requirements of the privacy legislation. This means, among other things, that:
                        <ul>
                            <li> clearly communicate why we process personal data. We do this by means of this privacy statement;</li>
                            <li> We aim to limit the collection of personal data only to personal data required for legitimate purposes;</li>
                            <li> we first ask for your explicit consent to process your personal data in cases that require your consent;</li>
                            <li> we take appropriate security measures to protect your personal data and we also require it from parties who process personal data on our behalf;</li>
                            <li> we respect your right to access your personal data or to have it corrected or deleted, upon your request.</li>
                        </ul>
                        <br />If you have any questions or want to know exactly what data we hold, please contact us.</p>
                        <h2>1. Purpose, data and retention period</h2>
                        We may collect or receive personal information for a variety of purposes related to our business operations which may include the following:
                        <h3>1.1 Contact - By phone, post, email and / or web forms</h3>
                        <p>For this purpose we use the following data:</p>
                        <ul>
                            <li>Name, Address and City</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                        </ul>
                        <p>The basis with which we process the data is:</p>
                        <a target="_blank" rel="noopener noreferrer nofollow" href="https://cookiedatabase.org/legal-bases/#consent">Subject to consent.</a>
                        <p><strong>Retention period</strong> </p>
                        <p>We keep this data at the end of the service for the following number of months: 12</p>
                        <h3>1.2 Newsletter</h3>
                        <p><strong>For this purpose we use the following data:</strong></p>
                        <ul>
                            <li>Email address</li>
                        </ul>
                        <p><strong>The basis with which we process the data is:</strong></p>
                        <a target="_blank" rel="noopener noreferrer nofollow" href="https://cookiedatabase.org/legal-bases/#agreement">It is necessary for the execution of a contract or preliminary procedures related to a contract of which the interested party is a party.</a>
                        <p><strong>Retention period</strong></p>
                        <p>We keep this data until the end of the service.</p>
                        <h3>1.3 To be able to comply with legal obligations</h3>
                        <p><strong>For this purpose we use the following data:</strong></p>
                        <ul>
                            <li>Name, Address and City</li>
                            <li>Email address</li>
                            <li>Tax information</li>
                            <li>Phone number</li>
                        </ul>
                        <p><strong>The basis with which we process the data is:</strong></p>
                        <a target="_blank" rel="noopener noreferrer nofollow" href="https://cookiedatabase.org/legal-bases/#consent">Subject to consent.</a>
                        <p><strong>Retention period </strong></p>
                        <p>We keep this data at the end of the service for the following number of months: 120</p>
                        <h3> 1.4 Compilation and analysis of statistics to improve the site.</h3>
                        <p><strong>For this purpose we use the following data:</strong></p>
                        <ul>
                            <li>IP address</li>
                            <li>Location</li>
                            <li>Visitor behavior</li>
                        </ul>
                        <p><strong>The basis with which we process the data is:</strong></p>
                        <a target="_blank" rel="noopener noreferrer nofollow" href="https://cookiedatabase.org/legal-bases/#legitimate">It is necessary for the purposes of the legitimate interests pursued by the controller or a third party, and this interest prevails over that of the person concerned.</a>
                        <p><strong>Retention period</strong></p> 
                        <p>We keep this data until the end of the service.</p>
                        <h2>2. Sharing with other parties</h2>
                        <p>We share or release this data to processors for the following reasons only:</p>
                        <p><strong>Processors</strong><br />
                            <b>Name: </b>Accounting Firm<br />
                            <b>Country: </b>Italy<br />
                            <b>Purpose: </b>Fiscal and tax management<br />
                        </p>
                        <h2>3. Cookies </h2>
                        <p>You can <a href="#cookie">read here</a> details about used cookies in this website.</p>
                        
                        <h2>4. Security</h2>
                        We are committed to the security of personal data. We take appropriate security measures to limit misuse and unauthorized access to personal data. This ensures that only the necessary people have access to your data, that access to the data is protected and that our security measures are regularly reviewed.
                        <h2>5. Third Party Websites</h2>
                        This privacy statement does not apply to third party websites linked by links on our website. We cannot guarantee that these third parties will handle your personal data in a reliable and secure manner. We recommend that you read the privacy statements of these websites before using them.
                        <h2>6. Fines regarding this privacy statement.</h2>
                        We reserve the right to make changes to this privacy statement. It is recommended that you consult this privacy statement regularly to be aware of any changes. Furthermore, we will actively inform you where possible.
                        <h2>7. Access and modification of your data</h2>
                        <p>If you have any questions or want to know exactly what personal data we have about you, please contact us.</p>
                        <ul>
                        <li>You have the right to know when your personal data is needed, what happens to it, how long it will be kept.</li>
                        <li>Right of access: you have the right to access your personal data that we know of.</li>
                        <li>Right of rectification: you have the right to complete, correct, delete or block your personal data when you wish.</li>
                        <li>If you have any questions or problems relating to the accessibility of the website, please do not hesitate to contact us.</li>
                        <li>Right to transfer your data: you have the right to request all your data from the controller and transfer all of them to another controller.</li>
                        <li>Right to object: you have the right to object to the processing of your data. We will respect this choice, unless there is a valid basis for the process.</li>
                        </ul>
                        <p>Make sure you always clearly report who you are, so that you can be sure not to delete or modify the data of a wrong person.</p>                
                        <h2>8. Sending a complaint</h2>
                        If you are not satisfied with how we manage (a complaint about) the processing of your personal data, you have the right to send a complaint to the Guarantor for the protection of personal data.
                        <h2>9. Contact details</h2>
                        <p>Stefano Coggiola <br />
                            Via Sanremo, 8 - 10137 Turin TO <br />
                            Italy <br />
                            Website: <a href="https://www.stefanocoggiola.com">https://www.stefanocoggiola.com</a><br />
                            Email: <a href="mailto:me&#x40;stefanocoggiola&#046;c&#x6f;m">me&#x40;stefanocoggiola&#046;c&#x6f;m</a><br />
                            Telephone number: 011/19567111<br />
                        </p>
                        <h2>About me</h2>
                        My website address is: https://www.stefanocoggiola.com.
                        <h2>Comments</h2>
                        This website doesn't use comments.                
                        <h2>Media</h2>
                        If you upload images to the website, you should avoid uploading images that include embedded location data (EXIF GPS). Visitors to the website can download and extract any location data from images on the website.
                        <h2>Embedded content from other websites</h2>
                        Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in exactly the same way as if the visitor had visited the other website.<br />
                        These websites may collect data about you, use cookies, integrate further third-party tracking and monitor your interaction with them, including tracking your interaction with the embedded content if you have an account and are logged in to those websites.
                        <h2>Who we share your data with</h2>
                        We do not share your data with anyone.
                        <h2>How long do we keep your data</h2>
                        Your data is kept for the shortest possible time, or until the end of its use or for the terms imposed by law.
                        <h2>What rights do you have over your data</h2>
                        If you have an account on this site, or have left comments, you can request to receive a file exported from the site with the personal data we hold about you, including any data you have provided to us. You can also request that we delete all personal data concerning you. This does not include any data we are required to keep for administrative, legal or security purposes.
                        <h2>Where we send your data</h2>
                        Visitor comments may be checked through an automated spam detection service.
                        <h2>People on our team who have access</h2>
                        Our team members have access to the information you provide to us; for example they can access:
                        <ul>
                            <li>Customer information such as your name, email address and billing and shipping information.</li>
                        </ul>
                        Our team members have access to this information to help fulfill orders, process refunds and support you.
                    </div>
                </div>          
                <hr />
                <div className="row" id="cookie">
                    <div className="col-md-12 text-left">
                        <br />
                        <h2 className="text-center">Cookie Policy</h2>
                        <script id="CookieDeclaration" data-culture="EN" src="https://consent.cookiebot.com/02073524-b914-4372-b6f7-fe198149c634/cd.js" type="text/javascript" async></script>
                        
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12 text-left">
                    <br /> <br /><Link to="/" className='btn btn-sm btn-dark'>Go back</Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Blog1