import React from 'react';
import { Link } from "react-router-dom"

const Blog1 = () => {
    return (
      <div id="top">
        <div id="fh5co-about" className="animate-box">
            <div className="container">
                <div className="row">
                <div className="col-md-10 col-md-offset-1 text-center fh5co-heading">
                    <div className="row">
                    <div className="col-md-9 text-left">
                        <h2>Why SSL is important?</h2>
                    </div>
                    <div className="col-md-3">
                        <Link to="/" className='btn btn-sm btn-dark text-right'>Go back</Link>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 text-left">
                        <br />
                        <div className="fh5co-blogg animate-box">
                            <span className="blogg-bg blog1"></span>
                        </div>
                        <p>Secure Socket Layer (SSL) is an Internet Protocol for securing data transfer between a user's browser and the website they are visiting.</p>
                        <p>It converts http:// to <strong>https://</strong></p>
                        <p>It's easy to know if a website is using SSL: a padlock is displayed on the address bar, near the URL</p>
                        <p>SSL provides encryption for our day to day communications between machines.</p>
                        <ul>
                            <li>SSL provides authentication</li>
                            <li>SSL affirms your identity</li>
                            <li>SSL grants a better search engine ranking</li>
                            <li>SSL improves customers trust</li>
                        </ul>
                        <p>To get a SSL certificate for your website you can refer to:</p>
                        <ul>
                            <li><a href="https://ssl.comodo.com" target="_blank" rel="noreferrer">Comodo (free and paid)</a></li>
                            <li><a href="https://www.cloudflare.com" target="_blank" rel="noreferrer">Cloudflare (free and paid)</a></li>
                            <li><a href="https://letsencrypt.org" target="_blank" rel="noreferrer">Let's Encrypt (free)</a></li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-left">
                        <br /> <br /><Link to="/" className='btn btn-sm btn-dark'>Go back</Link>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    )
}

export default Blog1