import React from 'react'
import { Link } from "react-router-dom"

import ContactMe from './ContactMe'

var today = new Date();
var birthDate = new Date("1964/12/10");
var startExperience = new Date("1994/05/10");
var age = today.getFullYear() - birthDate.getFullYear();
var experience = today.getFullYear() - startExperience.getFullYear();
var m = today.getMonth() - birthDate.getMonth();
if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
}
var mm = today.getMonth() - startExperience.getMonth();
if (mm < 0 || (mm === 0 && today.getDate() < startExperience.getDate())) {
    experience--;
}
var eta = age;
var esperienza = experience;

const Homepage = () => {
    return (
    <div id="top">
        <div id="fh5co-about" className="animate-box">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                        <h2>About Me</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <ul className="info">
                            <li><span className="first-block"><i className="icon-man"></i> Full Name:</span><span className="second-block">Stefano Coggiola</span></li>
                            <li><span className="first-block"><i className="icon-v-card"></i> Age:</span><span className="second-block">{eta}</span></li>
                            <li><span className="first-block"><i className="icon-target2"></i> of which:</span><span className="second-block">{esperienza} years on the web</span></li>
                            <li><span className="first-block"><i className="icon-old-phone"></i> Turin:</span><span className="second-block">+39(0)11 195 67 111</span></li>
                            <li><span className="first-block">&nbsp; &nbsp;Rome:</span><span className="second-block">+39(0)6 622 79 218</span></li>
                            <li><span className="first-block">&nbsp; &nbsp;Perugia:</span><span className="second-block">+39(0)75 782 77 69</span></li>
                            <li><span className="first-block"><i className="icon-mail"></i> E-mail:</span><span className="second-block"><a href="mailto:me&#x40;stefanocoggiola&#046;com">me&#x40;stefanocoggiola&#046;c&#x6f;m</a></span></li>
                            <li><span className="first-block"><i className="icon-globe2"></i> Websites:</span><span className="second-block">www.stefanocoggiola.com<br /><a href="https://www.coggiola.net" target="_blank" rel="noreferrer">www.coggiola.net</a></span></li>
                            <li><span className="first-block"><i className="icon-address"></i> Address:</span><span className="second-block">Turin, Rome, Perugia. Italy</span></li>
                            <li><span className="first-block"><i className="icon-flag4"></i> Spoken languages:</span><span className="second-block">Italian, English, French</span></li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <h2>Hello There!</h2>
                        <p>For over 25 years I have been working on dedicated internet and intranet solutions and marketing with new media.</p>
                        <p>My passion and constant updating have led me to collaborate with important companies for whom I have dealt with online communication (and not only), S.E.O. and Web and/or Social Marketing initiatives, as well as e-commerce projects and branding initiatives.</p>
                        <ul className="fh5co-social-icons">
                            <li><a href="https://www.linkedin.com/in/stefanocoggiola/" target="_blank" rel="noreferrer"><i className="icon-linkedin2"></i></a></li>
                            <li><a href="https://www.instagram.com/webdev_italia/" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="fh5co-resume" className="fh5co-bg-color">
                <div className="container">
                    <div className="row animate-box">
                        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                            <h2>My Resume</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-md-offset-0">
                            <ul className="timeline">
                                <li className="timeline-heading text-center animate-box">
                                    <div><h3>Work Experience</h3></div>
                                </li>
                                <li className="animate-box timeline-unverted">
                                    <div className="timeline-badge"><i className="icon-suitcase"></i></div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h3 className="timeline-title">Senior Developer</h3>
                                            <span className="company">IDEA Business - 2002 - Current</span>
                                        </div>
                                        <div className="timeline-body">
                                            <p>I develope with .NET with MS SQL Server customers web applications, but since 2018 I usually work with modern languages and framework, in serveless universe, such as <strong>NodeJS</strong>, Express, <strong>MongoDB</strong>, Handlebars, Angular JS (a very little bit) and <strong>ReactJS</strong>.<br />
                                            For non-complex projects I use WordPress and for e-commerce solutions - in addition to custom development - sometimes I use WooCommerce or PrestaShop.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-inverted animate-box">
                                    <div className="timeline-badge"><i className="icon-suitcase"></i></div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h3 className="timeline-title">Junior Developer</h3>
                                            <span className="company">IDEA Business - 1999 - 2002</span>
                                        </div>
                                        <div className="timeline-body">
                                            <p>I started with Classic ASP and for a long time I developed solutions based on databases (MS Access, mySql and MS SQL Server) with this Microsoft language.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="animate-box timeline-unverted">
                                    <div className="timeline-badge"><i className="icon-suitcase"></i></div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h3 className="timeline-title">UI/UX Designer</h3>
                                            <span className="company">IDEA Business - 1996 - Current</span>
                                        </div>
                                        <div className="timeline-body">
                                            <p>I started creating web interfaces in HTML 1.0 and I updated my knowledge since last HTML versions. Then I get involved in Bootstrap, Tailwind , Materialize and Material UI</p>
                                        </div>
                                    </div>
                                </li>

                                <br />
                                <li className="timeline-heading text-center animate-box">
                                    <div><h3>Education</h3></div>
                                </li>
                                <li className="timeline-inverted animate-box">
                                    <div className="timeline-badge"><i className="icon-graduation-cap"></i></div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h3 className="timeline-title">Political Sciences</h3>
                                            <span className="company">University of Turin</span>
                                        </div>

                                    </div>
                                </li>
                                <li className="animate-box timeline-unverted">
                                    <div className="timeline-badge"><i className="icon-graduation-cap"></i></div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h3 className="timeline-title">Economics and Business</h3>
                                            <span className="company">University of Turin</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-inverted animate-box">
                                    <div className="timeline-badge"><i className="icon-graduation-cap"></i></div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h3 className="timeline-title">French Course</h3>
                                            <span className="company">Centre Culturel Français de Turin</span>
                                        </div>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div><div id="fh5co-features" className="animate-box">
                <div className="container">
                    <div className="services-padding">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                                <h2>Services I love to offer</h2>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 col-md-offset-3 text-center bordered">
                                <div className="feature-left">
                                    <Link to="web-presence-strategic-consultant">
                                        <span className="icon">
                                            <i className="icon-bargraph"></i>
                                        </span>
                                        <div className="feature-copy">
                                            <h3>Web Presence & Opportunities Strategic Consultant</h3>
                                            <p>My {esperienza} years experience for the online growth of your business.<br />
                                            In-depth consultations <u>to learn first</u> about the criticalities, opportunities and alternatives of what is proposed to you as a secure revenue channel for your business.</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <div className="feature-left">
                                    <span className="icon">
                                        <i className="icon-paintbrush"></i>
                                    </span>
                                    <div className="feature-copy">
                                        <h3>Web Design</h3>
                                        <p>There are plenty of beautiful sites. Sites that communicate to their target audience also.<br />
                                            It is a pity that these aspects are often not contemporary.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 text-center">
                                <div className="feature-left">
                                    <span className="icon">
                                        <i className="icon-circle-compass"></i>
                                    </span>
                                    <div className="feature-copy">
                                        <h3>Branding</h3>
                                        <p>From logo design and development to strategies to make your brand known and recognised.<br />
                                            Target and competitor analysis to take action</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 text-center">

                                <div className="feature-left">
                                    <span className="icon">
                                        <i className="icon-code"></i>
                                    </span>
                                    <div className="feature-copy">
                                        <h3>Web Developent</h3>
                                        <p>
                                            "This application is really easy to use and intuitive".<br />
                                            This is the nicest compliment I have ever heard (often).
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-4 text-center">
                                <div className="feature-left">
                                    <span className="icon">
                                        <i className="icon-basket"></i>
                                    </span>
                                    <div className="feature-copy">
                                        <h3>E-commerce</h3>
                                        <p>Creating an e-commerce site is not enough to be successful.<br />
                                            The organisation, logistics, target group and competitors must be carefully assessed. I will guide you through all these steps.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 text-center">
                                <div className="feature-left">
                                    <span className="icon">
                                        <i className="icon-strategy"></i>
                                    </span>
                                    <div className="feature-copy">
                                        <h3>Web Marketing</h3>
                                        <p>In words, everyone sells online nowadays.<br />
                                            But really succeeding requires constant commitment and knowledge of the market and tools.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="feature-left">
                                    <span className="icon">
                                        <i className="icon-chat"></i>
                                    </span>
                                    <div className="feature-copy">
                                        <h3>Training</h3>
                                        <p>I also provide training on all aspects of the above services.<br />
                                            Online, via webinars or in person, depending on your needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div id="fh5co-skills" className="animate-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                            <h2>Skills</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="progress-wrap">
                                <h3><span className="name-left">HTML5/CSS3</span><span className="value-right">95%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-5 progress-bar-striped active bar1" role="progressbar"
                                        aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">WordPress</span><span className="value-right">92%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-5 progress-bar-striped active bar2" role="progressbar"
                                        aria-valuenow="92" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">E-Commerce platforms (WooCommerce, Prestashop)</span><span className="value-right">87%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-1 progress-bar-striped active bar5" role="progressbar"
                                        aria-valuenow="87" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">Relational Databases (MS SQL, mySQL)</span><span className="value-right">83%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-1 progress-bar-striped active bar6" role="progressbar"
                                        aria-valuenow="83" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">.NET (Visual Basic)</span><span className="value-right">90%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-5 progress-bar-striped active bar3" role="progressbar"
                                        aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="progress-wrap">
                                <h3><span className="name-left">Design</span><span className="value-right">93%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-5 progress-bar-striped active bar4" role="progressbar"
                                        aria-valuenow="93" aria-valuemin="93" aria-valuemax="93">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">Web marketing</span><span className="value-right">78%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-2 progress-bar-striped active bar6" role="progressbar"
                                        aria-valuenow="78" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">Social Media</span><span className="value-right">74%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-2 progress-bar-striped active bar6" role="progressbar"
                                        aria-valuenow="74" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">No SQL Database (MongoDB)</span><span className="value-right">87%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-1 progress-bar-striped active bar5" role="progressbar"
                                        aria-valuenow="87" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                            <div className="progress-wrap">
                                <h3><span className="name-left">JavaScript (NodeJS, React, Angular)</span><span className="value-right">85%</span></h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-1 progress-bar-striped active bar5" role="progressbar"
                                        aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><div id="fh5co-work" className="fh5co-bg-scuro">
                <div className="container">
                    <div className="row animate-box">
                        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                            <h2>Some demo projects</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 text-center col-padding animate-box">
                            <a href="https://amzn.stefanocoggiola.com/" target="_blank" className="work demo5" rel="noreferrer">
                                <div className="desc">
                                    <h3>Amazon.it Scraping for Technologic Products </h3>
                                    <span>React JS Web App with remote API backend using MongoDB</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4 text-center col-padding animate-box">
                            <a href="https://courses.stefanocoggiola.com/" target="_blank" className="work demo3" rel="noreferrer">
                                <div className="desc">
                                    <h3>React Courses Catalog</h3>
                                    <span>React JS Web App with remote API backend using MongoDB</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4 text-center col-padding animate-box">
                            <a href="https://demos.stefanocoggiola.com/random-passwords.html" target="_blank" className="work demo1" rel="noreferrer">
                                <div className="desc">
                                    <h3>Random Password Generator</h3>
                                    <span>With Copy & Paste function</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4 text-center col-padding animate-box">
                            <a href="https://demos.stefanocoggiola.com/qrcode-generator.html" target="_blank" className="work demo2" rel="noreferrer">
                                <div className="desc">
                                    <h3>QR Code Generator</h3>
                                    <span>With download option</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4 text-center col-padding animate-box">
                            <a href="https://compressir.com" target="_blank" className="work demo4" rel="noreferrer">
                                <div className="desc">
                                    <h3>Compressir</h3>
                                    <span>Online image compressor without quality loss</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4 text-center col-padding animate-box">
                            <a href="https://app.weddo.pro" target="_blank" className="work demo6" rel="noreferrer">
                                <div className="desc">
                                    <h3>weddo</h3>
                                    <span>Online Wedding Planner Management</span>
                                </div>
                            </a>
                        </div>
                        {/* <div className="col-md-3 text-center col-padding animate-box">
                            <div className="desc">
                                <h3 className="text-white">More demo<br />soon available</h3>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div><div id="fh5co-work" className="fh5co-bg-dark">
                <div className="container">
                    <div className="row animate-box">
                        <div className="col-md-12 col-md-offset-0 text-center fh5co-heading">
                            <h2>Some Customers / Own commercial projects</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="https://www.ibow.com" target="_blank" className="work client3" rel="noreferrer">
                                <div className="desc">
                                    <h3>IDEA Business</h3>
                                    <i className="arancio">own project</i>
                                    <span>Founder, CEO & CTO</span>
                                    <span>www.ibow.com</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="https://www.weddo.pro" target="_blank" className="work client8" rel="noreferrer">
                                <div className="desc">
                                    <h3>weddo</h3>
                                    <i className="arancio">own project</i>
                                    <span>Fullstack Management System for Wedding and Event Planners</span>
                                    <span>www.weddo.pro</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client1">
                                <div className="desc">
                                    <h3>ATC Torino</h3>
                                    <span>Consulting for Web solutions</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client2">
                                <div className="desc">
                                    <h3>CR Saluzzo</h3>
                                    <span>Consulting & Development for Bank communication</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client4">
                                <div className="desc">
                                    <h3>Intesa San Paolo</h3>
                                    <span>Payment Getaway Platform Consulting</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#fh5co-consult" className="work client7">
                                <div className="desc">
                                    <h3>Your IDEA?</h3>
                                    <b className="arancio">Your own project</b>
                                    <span>Contact me without obligation and let's evaluate it!</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client5">
                                <div className="desc">
                                    <h3>L'Oreal Saipo</h3>
                                    <span>Digital Signage project for Settimo Torinese plant</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client6">
                                <div className="desc">
                                    <h3>Navale Assicurazioni</h3>
                                    <span>Web App for selling travel insurance B2B</span>
                                </div>
                            </a>
                        </div>


                        {/* NUOVE REALIZZAZIONI */}
                        
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="https://www.compressir.com" target="_blank" className="work client9" rel="noreferrer">
                                <div className="desc">
                                    <h3>CompresSir</h3>
                                    <i className="arancio">own project</i>
                                    <span>Fullstack web application to compress images no quality loss</span>
                                    <span>www.compressir.com</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client11">
                                <div className="desc">
                                    <h3>Very Norma People</h3>
                                    <span>PWA with touristic and commercial infos for a little italian town community</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="https://www.ponthouse.com" target="_blank" className="work client12" rel="noreferrer">
                                <div className="desc">
                                    <h3>Ponthouse</h3>
                                    <i className="arancio">own project</i>
                                    <span>Real estate listings with Virtual Tours 360° support</span>
                                    <span>www.ponthouse.com</span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center col-padding animate-box">
                            <a href="#!" className="work client10">
                                <div className="desc">
                                    <h3>Destination Wedding Umbria</h3>
                                    <i className="arancio">own project</i>
                                    <span>In progress</span>
                                </div>
                            </a>
                        </div>
                        {/* FINE NUOVE */}
                    </div>
                </div>
            </div><div id="fh5co-blog">
                <div className="container">
                    <div className="row animate-box">
                        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                            <h2>Blog articles</h2>
                            <p>I have little time, so I only publish on topics that I think are really important.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="fh5co-blog animate-box">
                                <Link to="blog4" className="blog-bg blog4"></Link>
                                <div className="blog-text">
                                    {/* <span className="posted_on">January 24th 2022</span> */}
                                    <h3><Link to="blog4">Facebook and Instagram become paid: is this such a bad thing in 2023?</Link></h3>
                                    <ul className="stuff">
                                        <li><Link to="blog4">Read More <i className="icon-arrow-right22"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fh5co-blog animate-box">
                                <Link to="blog1" className="blog-bg blog1"></Link>
                                <div className="blog-text">
                                    {/* <span className="posted_on">January 24th 2022</span> */}
                                    <h3><Link to="blog1">Why SSL is important?</Link></h3>
                                    <p>Secure Socket Layer (SSL) is an Internet Protocol for securing data transfer between a user's browser and...</p>
                                    <ul className="stuff">
                                        <li><Link to="blog1">Read More <i className="icon-arrow-right22"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fh5co-blog animate-box">
                                <Link to="blog2" className="blog-bg blog2"></Link>
                                <div className="blog-text">
                                    {/* <span className="posted_on">January 2nd 2022</span> */}
                                    <h3><Link to="blog2">Blockchain in simple words</Link></h3>
                                    <p>Blockchain is a chain of blocks.We can consider this chain of blocks as a <i>digital immutable ledger</i>.</p>
                                    <ul className="stuff">
                                        <li><Link to="blog2">Read More<i className="icon-arrow-right22"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fh5co-blog animate-box">
                                <Link to="blog3" className="blog-bg blog3"></Link>
                                <div className="blog-text">
                                    {/* <span className="posted_on">Mar. 15th 2016</span> */}
                                    <h3><Link to="blog3">What is an API</Link></h3>
                                    <p>API stands for <strong>Application Program Interface</strong>.</p>
                                    <p><strong>API</strong> is a computing interface that defines...</p>
                                    <ul className="stuff">
                                        <li><Link to="blog3">Read More<i className="icon-arrow-right22"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fh5co-started" className="fh5co-bg-dark">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row animate-box">
                        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                            <h2>Hire Me!</h2>
                            <p>Any idea? Do you need some help?<br />Contact me without obligation and let's evaluate the opportunities together</p>
                            <p><a href="#fh5co-consult" className="btn btn-default btn-lg">Contact me</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fh5co-consult">
                <div className="video fh5co-video video1">
                    <div className="overlay"></div>
                </div>
                <div className="choose animate-box">
                    <h2>Contact me!</h2>
                    <ContactMe />
                </div>
            </div>
        </div>
    )
}

export default Homepage