import React from 'react';
import { Link } from "react-router-dom"

import ContactMe from './ContactMe'

var today = new Date();
var startExperience = new Date("1994/05/10");
var experience = today.getFullYear() - startExperience.getFullYear();
var mm = today.getMonth() - startExperience.getMonth();
if (mm < 0 || (mm === 0 && today.getDate() < startExperience.getDate())) {
    experience--;
}
var esperienza = experience;

const Blog0 = () => {
    return (
      <div id="top">
        <div id="fh5co-about" className="animate-box">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center fh5co-heading">
                <div className="row">
                  <div className="col-md-9 text-left">
                    <h2>Web Presence & Opportunities Strategic Consultant</h2>
                  </div>
                  <div className="col-md-3">
                    <Link to="/" className='btn btn-sm btn-dark text-right'><i className="icon-home"></i> Home</Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <br />
                    <img src="/images/uk.png" className="flag" alt="English version" /><br /> <br />
                    <p>Every professional or company is considering implementing or reviewing its Internet presence to <b>increase its business</b>.</p>
                    <p>Unfortunately, due to too much 'hearsay' and at least partial information from those who are proposing a product or service to you, you too often end up wasting money on activities that are not functional, or even compatible, with your business reality.</p>
                    <p>This is why you can take advantage of my <u>consultancy service</u> based on {esperienza} years experience and on a careful <u>analysis</u> of your specific reality, of your <u>objectives</u>, pointing out all those aspects that are 'behind' what seems to be the deal of the century.</p>
                    <p>For example: are you planning to <b>open an e-commerce</b>, since with the pandemic many businesses have moved to the internet? Have you considered what this entails in terms of logistics, shipping, warehousing (remember, you're always going up against Amazon!), the incidence of payment getaway commissions, and all the aspects impacting your organisation?</p>
                    <p>In other cases I have seen activity on social networks typical of when one aims to create a brand identity, but for companies that were already a reference for their sector and therefore should have put more effort into another type of activity on these channels.</p>
                  </div>
                  <div className="col-md-6 text-left">
                    <br />
                    <img src="/images/it.png" className="flag" alt="Versione in italano" /><br /> <br />
                    <p>Ogni professionista o azienda sta valutando di implementare o di rivedere la sua presenza su internet per <b>aumentare il suo business</b>.</p>
                    <p>Purtroppo, a causa di troppi "sentito dire" e informazioni quantomeno parziali da parte di chi ti sta proponendo un prodotto o servizio, troppo spesso finisci con lo sperperare soldi in attività che non sono funzionali, o anche solo compatibili, con la tua realtà aziendale.</p>
                    <p>Per questo puoi usufruire del mio <b>servizio di consulenza</b> basato su {esperienza} anni di esperienza ed un'attenta <u>analisi</u> della tua specifica realtà, dei tuoi <u>obiettivi</u>, indicandoti tutti quegli aspetti che stanno "dietro" a quello che sembra l'affare del secolo.</p>
                    <p>Ad esempio: hai intenzione di <b>aprire un e-commerce</b>, visto che con la pandemia molti affari si sono spostati su internet? Hai considerato cosa comporta a livello di logistica, spedizioni, magazzino (ricorda che ti vai a scontrare sempre e comunque con Amazon!), l'incidenza delle commissioni dei getaway di pagamento e tutti gli aspetti impattanti sulla tua organizazzione?</p>
                    <p>In altri casi ho avuto modo di vedere attività sui social networks tipiche di quando si mira a creare la brand identity, ma per aziende che erano già un riferimento per il loro settore e quindi avrebbero dovuto impegnarsi maggiormente su un altro tipo di attività su questi canali.</p>
                  </div>
                </div>
                <div id="fh5co-work" className="fh5co-blogg animate-box">
                  <span className="blogg-bg blog0"></span>
                </div>                
                <div className="row">
                  <div className="col-md-6 text-left">
                    <br />
                    <img src="/images/uk.png" className="flag" alt="English version" /><br /> <br />
                    <h3>My Method</h3>
                    <p>In order to identify the needs and possible criticalities of your professional activity or your company, it is necessary, on my part, to acquire a variety of information, starting with your company organisation, which aspects of your production/organisation chain represent a greater cost or a greater use of time and/or personnel, or are not adequately structured to meet the needs you hope to solve with a new online initiative.</p>
                    <p>On this basis, I will point out to you the criticalities that have emerged, how you could remedy them and possible alternative initiatives that are more profitable for you.</p>
                    <p>And if you wish, I can also support you in identifying and choosing the most suitable provider for your needs.</p>
                  </div>
                  <div className="col-md-6 text-left">
                    <br />
                    <img src="/images/it.png" className="flag" alt="Versione in italano" /><br /> <br />
                    <h3>Il mio metodo</h3>
                    <p>Per identificare le necessità e le eventuali criticità della tua attività professionale o della tua azienda è necessario, da parte mia, acquisire svariate informazioni, a partire dall'organizzazione aziendale, quali sono gli aspetti della tua filiera produttiva/organizzativa che rappresentano un maggior costo o un maggior impiego di tempo e/o personale o ancora che non sono adeguatamente strutturati per rispondere alle esigenze che speri di risolvere con una nuova iniziativa on-line.</p>
                    <p>Su questa base ti sottolineerò le criticità emerse, come potresti ovviare ad esse ed eventuali iniziative alternative più remunerative per te.</p>
                    <p>E se lo vorrai, potrò anche supportarti nell'individuazione e nella scelta del fornitore più adatto alle tue esigenze.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <br />
                    <img src="/images/uk.png" className="flag" alt="English version" /><br /> <br />
                    <h3>What I provide you with</h3>
                    <p>I have been working on the internet presence for professionals, associations and companies of all sizes since 1994, and since 1996 I started to realise first websites, then web applications for these types of clients.</p>
                    <p>In addition, I have gained extensive experience in online marketing channels and the psychology of web users, which allows me to anticipate their feelings and perceptions very often.</p>
                    <p>In summary, I can provide you with:
                      <ul>
                        <li>over 25 years of experience ({esperienza} years to be exact) on and with the Internet;</li>
                        <li>in-depth knowledge of internet tools and the opportunities it offers;</li>
                        <li>technical expertise regarding web development to bring you new, sustainable ideas;</li>
                        <li>knowledge of the psychology of Internet users;</li>
                        <li>ability to analyse business issues;</li>
                        <li>strong problem-solving skills.</li>
                      </ul>
                    </p>
                    <p></p>
                  </div>
                  <div className="col-md-6 text-left">
                    <br />
                    <img src="/images/it.png" className="flag" alt="Versione in italano" /><br /> <br />
                    <h3>Cosa ti metto a disposizione</h3>
                    <p>Mi occupo di presenza su internet per professionisti, associazioni e aziende di ogni dimensione sin dal 1994 e dal 1996 ho iniziato a realizzare prima siti web, poi applicazioni web per questo tipo di clienti.</p>
                    <p>Inoltre ho maturato una vasta esperienza riguardo i canali di marketing on-line e alla psicologia degli Utenti web che mi permette di poterne anticipare molto spesso il sentire e le percezioni.</p>
                    <p>Riassumendo, ti posso mettere a disposizione:
                      <ul>
                        <li>oltre 25 anni di esperienza (per l'esattezza ben {esperienza} anni) su e con internet;</li>
                        <li>approfondita competenza circa gli strumenti di internet e le opportunità che questa offre;</li>
                        <li>competenze tecniche riguardo lo sviluppo web per proporti nuove idee sostenibili;</li>
                        <li>conoscenza della psicologia degli Utenti di internet;</li>
                        <li>capacità di analisi delle problematiche aziendali;</li>
                        <li>forte predisposizione al problem-solving.</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fh5co-started" className="fh5co-bg-dark">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row animate-box">
                        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                            <h2>First call free</h2>
                            <p>I will provide you with a free 1-hour first call</p>
                            <p><a href="#fh5co-consult" className="btn btn-default btn-lg">Request now</a></p>
                            <h2>Prima call gratuita</h2>
                            <p>Ti metto a disposizione una prima call gratuita di 1 ora</p>
                            <p><a href="#fh5co-consult" className="btn btn-default btn-lg">Richiedi subito</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fh5co-consult">
                <div className="video fh5co-video video1">
                    <div className="overlay"></div>
                </div>
                <div className="choose animate-box">
                    <h2><img src="/images/uk.png" className="flag" alt="English version" /> Require a first free call!</h2>
                    <h2><img src="/images/it.png" className="flag" alt="Versione in italano" /> Richiedi una prima call gratuita</h2>
                    <ContactMe />
                </div>
            </div>
      </div>
    )
}

export default Blog0