import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import './css/Style.css';
import './App.css';

/// IMPORT COMPONENTS ///
import Header from './components/Header'
import Blog0 from './components/WebPresenceStrategicConsultant'
import Blog1 from './components/Blog1'
import Blog2 from './components/Blog2'
import Blog3 from './components/Blog3'
import Blog4 from './components/Blog4'
import Policies from './components/Policies'
import Homepage from './components/Homepage'
import ScrollToTop from "./components/ScrollToTop";
/// END IMPORT COMPONENTS ///

const App = () => {    
  return (
   <Router>	
      <ScrollToTop>
        <div id="page">	
        <Header />
            <Routes>
              <Route path='/web-presence-strategic-consultant' element={<Blog0 />} />
              <Route path='/policies' element={<Policies />} />
              <Route path='/blog1' element={<Blog1 />} />
              <Route path='/blog2' element={<Blog2 />} />
              <Route path='/blog3' element={<Blog3 />} />
              <Route path='/blog4' element={<Blog4 />} />
              <Route path='/' element={<Homepage />} />
            </Routes>
        </div>
      </ScrollToTop>
   </Router>
  );
}

export default App;