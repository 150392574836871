import React, { useRef } from 'react';
import { Link } from "react-router-dom"
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const ContactMe = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_gsjp1bv', 'template_5axrmpj', form.current, 'user_ERNInge9Luc8VXFe1cy5D')
    .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset()
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="row form-group">
        <div className="col-md-12 col-sm-12">
            <input type="name" name="from_name" id="from_name" className="form-control" placeholder="Your complete name" required aria-required="true"  />
        </div>
        </div>

        <div className="row form-group">
            <div className="col-md-12 col-sm-12">
                <input type="email" name="email" id="email" className="form-control" placeholder="Your e-mail address" required aria-required="true"  />
            </div>
        </div>

        <div className="row form-group">
            <div className="col-md-12 col-sm-12">
                <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject of your request" required aria-required="true"  />
            </div>
        </div>

        <div className="row form-group">
            <div className="col-md-12 col-sm-12">
                <textarea name="message" id="message" className="form-control" placeholder="Tell me something about you or you need... " required aria-required="true" ></textarea>
            </div>
        </div>

        <div className="row form-group">
            <div className="col-md-12 col-sm-12">
                <input type="checkbox" name="privacy" id="privacy" className="form-control-check" required aria-required="true" /> 
                <label> &nbsp; You agree to our <Link to='policies'>Privacy Policy</Link></label>
            </div>
        </div>
        <div className="form-group text-right">
            <button type="submit" className="btn btn-primary">Send Message <i className="icon-arrow-right22"></i></button>
        </div>
    </form>
  )
}

export default ContactMe