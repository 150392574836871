import { Link } from 'react-router-dom'

const Header = () => {
  return (
      <header id="fh5co-header" className="fh5co-cover js-fullheight testa" role="banner" data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center">
              <div className="display-t js-fullheight">
                <div className="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                  <div className="profile-thumb foto-profilo"></div>
                  <h1><span>Stefano Coggiola</span></h1>
                  <h3><span>Fullstack Web developer & designer</span></h3><br />
                  {/* <h3><span className="headerLink"><Link to="web-presence-strategic-consultant" className="text-black"><i className="icon-link2"></i> Strategic Consultant about Company Web presence optimization</Link></span></h3><br /> */}
                  <h3><Link to="web-presence-strategic-consultant" className="text-black"><i className="icon-link2"></i> Strategic Consultant about Company Web presence optimization</Link></h3><br />
                    <ul className="fh5co-social-icons">									
                      <li><a href="https://www.linkedin.com/in/stefanocoggiola/" target="_blank" rel="norefer noreferrer"><i className="icon-linkedin2"></i></a></li>
                      <li><a href="https://www.instagram.com/webdev_italia/" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a></li>
                    </ul>
                    <br /><img src="/images/stefano-coggiola-web-developer.png" className="logo" alt="Stefano Coggiola - Web developer" />
                    <div className="border text-center">Blog<br />
                      <a href="#fh5co-blog"><i className="icon-arrow-long-down"></i></a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}

export default Header