import React from 'react';
import { Link } from "react-router-dom"

const Blog3 = () => {
  return (
    <div id="top">
      <div id="fh5co-about" className="animate-box">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center fh5co-heading">
                <div className="row">
                  <div className="col-md-9 text-left">
                    <h2>What id an API</h2>
                  </div>
                <div className="col-md-3">
                  <Link to="/" className='btn btn-sm btn-dark text-right'>Go back</Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-left">
                  <br />
                  <div className="fh5co-blogg animate-box">
                    <a href="art02" className="blogg-bg blog3"></a>
                  </div>
                  <p>API stands for <strong>Application Program Interface</strong>.</p>
                  <p><strong>API</strong> is a computing interface that defines interactions between multiple software intermediaries.</p>
                  <p>It defines the kinds of call or requests that can be made, how to make theme, the data formats that should be used and so on...</p>
                  <p><strong>It's a mechanism that allows the interaction between two applications using a set of rules.</strong>.</p>
                  <p>You can see as in a restaurant: Customer (the App/Client) ask the Waiter (the API) who bring the request to the Kitchen (the Server) and then take to Customer result of his request.</p>
                  <p>There are different types of API. Let's take a look!</p>
                  <ul>
                    <li><strong>Open APIs</strong><br />
                        (or Public API) these APIs have no restrictions to access; they are available for everyone.
                    </li>
                    <li><strong>Partner APIs</strong><br />
                        Developer needs specific rights or license to access this type of API.
                    </li>
                    <li><strong>Internal APIs</strong><br />
                        (or Private API) are exposed only by internal systems. Usually designed fpr internal used within a company to be able to improve its products and/or services.
                    </li>
                    <li><strong>Composite APIs</strong><br />
                        This type of API combines different data and service APIs.<br />
                        It's a sequence of tasks that run synchronously as a result of the execution and not at the request of task.<br />
                        Main uses consist in speed up process of execution and improve the performance of the listeners in web and/or mobile interfaces.
                    </li>
                  </ul>
                  <p>APIs are used for example for Authentication of Users, Payment Processing, Data distribution and many other situations, to improve performances, centralize processes and distribute data to more than one application.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-left">
                  <br /> <br /><Link to="/" className='btn btn-sm btn-dark'>Go back</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog3