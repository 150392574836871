import React from 'react';
import { Link } from "react-router-dom"

const Blog2 = () => {
    return (
      <div id="top">
        <div id="fh5co-about" className="animate-box">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1 text-center fh5co-heading">
                <div className="row">
                  <div className="col-md-9 text-left">
                    <h2>Blockchain for beginners</h2>
                  </div>
                  <div className="col-md-3">
                    <Link to="/" className='btn btn-sm btn-dark text-right'>Go back</Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-left">
                    <br />
                    <div className="fh5co-blogg animate-box">
                      <a href="art02" className="blogg-bg blog2"></a>
                    </div>
                    <p>Blockchain is a chain of blocks. We can consider this chain of blocks as a <i>digital immutable ledger</i>.</p>
                    <p>But, what is a block?</p>
                    <p>A block consist of the transaction data, asset's information, previous block's hash and some other relevant stuff.</p>
                    <p>Every node has an entire copy of this <i>chain of blocks</i>.</p>
                    <p>We can consider a <strong>node</strong> as a single computer partecipating in the network.</p>
                    <p>This fact gives a network great strength, because it puts a huge emphasis on decentralization and security.</p>
                    <p>No one node is in the control of the network, and if some have to temper with data on the network, they have to change it in all the node, which means to hack all the single nodes in the network; which becomes more difficult than just changing it at one centralised database.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-left">
                    <br /> <br /><Link to="/" className='btn btn-sm btn-dark'>Go back</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Blog2